<template>
  <v-container fluid class="py-6">
    <v-row>
      <common-loading
        :title="loading.title"
        :message="loading.message"
        :loading_dialog="loading.dialog"
        :show_loading="loading.show_loading"
        :show_confirm="loading.show_confirm"
        v-on:confirm="loading.dialog = false"
      >
      </common-loading>
      <common-confirm
        :title="confirm.title"
        :message="confirm.message"
        :confirm_dialog="confirm.dialog"
        :confirm_function="confirm.function"
        v-on:confirm="confirm.dialog = false"
        v-on:cancel="confirm.dialog = false"
      ></common-confirm>
      <!-- create dialog -->
      <v-row>
        <v-dialog v-model="dialog" width="60%">
          <v-card>
            <v-row class="mx-4 mt-4">
              <v-col cols="12" md="8">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >標題</label
                >
                <v-text-field
                  hide-details
                  outlined
                  v-model="editItem.title"
                  :class="text_edit_class"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >公告類別</label
                >
                <v-select
                  :items="bulletin_type_enum_str"
                  hide-details
                  outlined
                  single-line
                  v-model="editItem.type"
                  :class="text_edit_class"
                >
                </v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >內容</label
                >
                <v-textarea
                  hide-details
                  outlined
                  v-model="editItem.content"
                  class="
                    font-size-input
                    placeholder-lighter
                    border border-radius-md
                    mt-2
                  "
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" class="py-0">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >連結</label
                >
                <v-text-field
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  v-model="editItem.link"
                  :class="text_edit_class"
                >
                </v-text-field>
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >開始時間</label
                >

                <v-menu
                  v-model="editItem.start_time_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      outlined
                      :class="text_edit_class"
                      :value="displayChineseYear(editItem.start_time)"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editItem.start_time"
                    :year-format="formatChineseYear"
                    locale="zh-tw"
                    @input="editItem.start_time_menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >結束時間</label
                >
                <v-menu
                  v-model="editItem.end_time_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hide-details
                      outlined
                      :class="text_edit_class"
                      :value="displayChineseYear(editItem.end_time)"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editItem.end_time"
                    :year-format="formatChineseYear"
                    locale="zh-tw"
                    @input="editItem.end_time_menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="4" cols="12" class="py-0">
                <label class="text-sm text-typo font-weight-bolder ms-1"
                  >狀態</label
                >
                <v-select
                  :items="bulletin_status_enum_str"
                  hide-details
                  outlined
                  single-line
                  v-model="editItem.status"
                  :class="text_edit_class"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-card-actions class="mx-4 my-0 py-0">
              <v-btn
                elevation="0"
                :ripple="false"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-secondary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                "
                color="#5e72e4"
                @click="dialog = false"
                >取消</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                :ripple="false"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                "
                color="#5e72e4"
                @click="onConfirm"
                >確定</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-grt font-weight-bolder text-lg">
            <h5 class="font-weight-bold text-h5 text-grt mb-0">置頂公告</h5>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default
                bg-gradient-default
                py-5
                px-2
                mx-1
              "
              small
              @click="onConfirmTop"
            >
              <v-icon class="me-2" size="12">fa fa-user-plus</v-icon>
              更新置頂
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row cols="2" class="mx-0" no-gutters>
              <v-col class="py-1">
                <label class="text-md font-weight-bolder ms-1">
                  本月成交建物
                </label>
                <v-text-field
                  hide-details
                  outlined
                  :class="text_edit_class"
                  suffix="件"
                  v-model="month.buildings"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="py-1">
                <label class="text-md font-weight-bolder ms-1">
                  本月成交土地
                </label>
                <v-text-field
                  hide-details
                  outlined
                  class="
                    input-style
                    font-size-input
                    border border-radius-md
                    mt-2
                  "
                  suffix="件"
                  v-model="month.lands"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="py-1">
                <label class="text-md font-weight-bolder ms-1">
                  本月累積業績
                </label>
                <v-text-field
                  hide-details
                  outlined
                  :class="text_edit_class"
                  suffix="萬"
                  v-model="month.sales"
                >
                </v-text-field>
              </v-col>
              <!-- lands -->
              <v-col cols="2" class="py-1">
                <label class="text-md font-weight-bolder ms-1">
                  今年成交建物
                </label>
                <v-text-field
                  hide-details
                  outlined
                  :class="text_edit_class"
                  suffix="件"
                  v-model="year.buildings"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="py-1">
                <label class="text-md font-weight-bolder ms-1">
                  今年成交土地
                </label>
                <v-text-field
                  hide-details
                  outlined
                  :class="text_edit_class"
                  suffix="件"
                  v-model="year.lands"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="py-1">
                <label class="text-md font-weight-bolder ms-1">
                  今年累積業績
                </label>
                <v-text-field
                  hide-details
                  outlined
                  :class="text_edit_class"
                  suffix="萬"
                  v-model="year.sales"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="px-0 py-0">
            <v-simple-table
              class="table border-radius-xl"
              style="table-layout: fixed"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-btn
                      :ripple="false"
                      elevation="0"
                      color="#fff"
                      class="
                        font-weight-bolder
                        btn-default
                        bg-gradient-primary
                        py-5
                        px-6
                        mx-3
                        ms-auto
                      "
                      small
                      @click="createBulletin"
                    >
                      <v-icon class="me-2" size="12">fa fa-user-plus</v-icon>
                      新增公告
                    </v-btn>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:default>
                <thead>
                  <tr>
                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="10%"
                    >
                      公告類別
                    </td>
                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="20%"
                    >
                      標題
                    </td>
                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="30%"
                    >
                      內容
                    </td>

                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="10%"
                    >
                      開始時間
                    </td>

                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="10%"
                    >
                      結束時間
                    </td>

                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="8%"
                    >
                      狀態
                    </td>
                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                      width="10%"
                    >
                      新增時間
                    </td>
                    <td
                      class="
                        text-secondary
                        font-weight-bolder
                        text-md
                        border-bottom
                      "
                    >
                      操作
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in bulletins" :key="item.id">
                    <td :class="{ 'border-bottom': i != bulletins.length - 1 }">
                      <span class="text-md mb-0">{{
                        item.type | getText(bulletin_type_enum_str)
                      }}</span>
                    </td>
                    <td :class="{ 'border-bottom': i != bulletins.length - 1 }">
                      <span class="text-md mb-0">{{ item.title }}</span>
                    </td>
                    <td :class="{ 'border-bottom': i != bulletins.length - 1 }">
                      <span class="text-md mb-0">{{ item.content }}</span>
                    </td>

                    <td :class="{ 'border-bottom': i != bulletins.length - 1 }">
                      <p class="text-md mb-0">
                        {{ item.start_time | dateFormatDash }}
                      </p>
                    </td>
                    <td :class="{ 'border-bottom': i != bulletins.length - 1 }">
                      <p class="text-md mb-0">
                        {{ item.end_time | dateFormatDash }}
                      </p>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != bulletins.length - 1 }"
                      class="text-md mb-0"
                    >
                      <span class="text-md mb-0">{{
                        item.status | getText(bulletin_status_enum_str)
                      }}</span>
                    </td>
                    <td :class="{ 'border-bottom': i != bulletins.length - 1 }">
                      <p class="text-md mb-0">
                        {{ item.create_time | dateOnly }}
                      </p>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != bulletins.length - 1 }"
                      class="text-end"
                    >
                      <v-btn
                        :ripple="false"
                        elevation="0"
                        color="#fff"
                        class="
                          font-weight-bold
                          text-sm
                          btn-danger
                          bg-gradient-default
                          ma-2
                          px-5
                        "
                        @click="editBulletin(item)"
                      >
                        編輯公告
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#cb0c9f"
          v-model="page"
          :length="getPageCount()"
          circle
          :total-visible="15"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import {
  bulletin_status_enum,
  bulletin_status_enum_str,
  bulletin_type_enum,
  bulletin_type_enum_str,
  common_text_edit_class,
} from "@/definitions";
import CommonUtility from "@/util/CommonUtility.js";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import { mapActions } from "vuex";
export default {
  name: "User-List",
  data: () => ({
    page: 1,
    bulletins_count: 0,
    itemsPerPage: 10,
    bulletins: [],
    bulletin_status_enum: bulletin_status_enum,
    bulletin_status_enum_str: bulletin_status_enum_str,
    bulletin_type_enum: bulletin_type_enum,
    bulletin_type_enum_str: bulletin_type_enum_str,
    text_edit_class: common_text_edit_class,
    editItem: {
      id: null,
      title: "",
      content: "",
      link: "",
      type: 0,
      start_time: null,
      end_time: null,
      status: 0,
      start_time_menu: false,
      end_time_menu: false,
    },
    dialog: false,
    loading: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
      show_confirm: false,
    },
    confirm: {
      dialog: false,
      title: null,
      message: null,
      function: null,
    },
    month: {
      buildings: 0,
      lands: 0,
      sales: 0,
    },
    year: {
      buildings: 0,
      lands: 0,
      sales: 0,
    },
  }),
  components: {
    CommonLoading,
    CommonConfirm,
  },
  mixins: [HttpCommonMixin],
  mounted() {
    this.getBulletins();
  },
  watch: {
    page() {
      this.getBulletins();
    },
  },
  methods: {
    ...mapActions(["setRoles"]),
    getPageCount() {
      return Math.ceil(this.bulletins_count / this.itemsPerPage);
    },
    getDisplayValue(val) {
      if (typeof val == "string") {
        val = val.trim();
      }
      if (isNaN(val) || val == null || val == undefined || val == "") {
        return null;
      }
      console.log("val", val, typeof val);

      return parseFloat(val);
    },
    getTopBulletins() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/top`;
      this.doHttpGet(url, {}, (result) => {
        console.log("top bulletins:", result);
        this.month.buildings = this.getDisplayValue(result.month_buildings);
        this.month.lands = this.getDisplayValue(result.month_lands);
        this.month.sales = this.getDisplayValue(result.month_sales);
        this.year.buildings = this.getDisplayValue(result.year_buildings);
        this.year.lands = this.getDisplayValue(result.year_lands);
        this.year.sales = this.getDisplayValue(result.year_sales);
      });
    },
    getBulletins() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      this.doHttpGet(url, params, this.setResult);
    },
    setResult(result) {
      console.log("result:", result.items);
      this.bulletins_count = result.total;
      this.bulletins = result.items;
      this.getTopBulletins();
    },
    isNotEmpty(value) {
      console.log("value", value, typeof value);
      // if (typeof value == "string" && value.length == 0) {
      //   return false;
      // }
      // return true;
      if (typeof value === "number") {
        return true;
      } else if (typeof value === "string" && value.trim() === "") {
        return true;
      }
      return false;
    },

    checkData() {
      // check all variables of month be number and not empty ""
      return (
        this.isNotEmpty(this.month.buildings) &&
        this.isNotEmpty(this.month.lands) &&
        this.isNotEmpty(this.month.sales) &&
        this.isNotEmpty(this.year.buildings) &&
        this.isNotEmpty(this.year.lands) &&
        this.isNotEmpty(this.year.sales)
      );
    },
    onConfirmTop() {
      // check if value in buildings is not empty and is number
      // if (this.checkData() == false) {
      //   this.loading = {
      //     dialog: true,
      //     message: "置頂公告內容必須為數字",
      //     show_loading: false,
      //     show_confirm: true,
      //   };
      //   return;
      // }

      this.confirm = {
        title: "置頂公告",
        message: "更新置頂公告?",
        function: this.onConfirmUpdateTop,
        dialog: true,
      };
    },
    onConfirmUpdateTop() {
      this.confirm.dialog = false;
      let msg = null;
      this.onUpdateTop();
      msg = "更新置頂公告";
      this.loading = {
        dialog: true,
        message: msg,
        show_loading: true,
      };
    },
    onUpdateTop() {
      // /api/v1/bulletins/top
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/top`;
      let data = {
        month_buildings: this.month.buildings,
        month_lands: this.month.lands,
        month_sales: this.month.sales,
        year_buildings: this.year.buildings,
        year_lands: this.year.lands,
        year_sales: this.year.sales,
      };
      this.doHttpPost(
        url,
        data,
        () => {
          this.onSuccess("更新置頂公告成功");
        },
        () => {
          this.onOperationFail("更新置頂公告失敗");
        }
      );
    },
    onOperationFail(msg) {
      this.dialog = false;
      setTimeout(() => {
        this.loading = {
          dialog: true,
          message: msg,
          show_loading: false,
          show_confirm: true,
        };
      }, 500);
    },
    onConfirm() {
      if (this.editItem.id == null) {
        this.confirm = {
          title: "新增公告",
          message: "確認新增公告?",
          function: this.onConfirmAction,
          dialog: true,
        };
      } else {
        this.confirm = {
          title: "更新公告",
          message: "確認更新公告?",
          function: this.onConfirmAction,
          dialog: true,
        };
      }
    },
    onConfirmAction() {
      this.confirm.dialog = false;
      let msg = null;
      if (this.editItem.id == null) {
        this.onCreateBulletin();
        msg = "新增公告";
      } else {
        this.onUpdateBulletin();
        msg = "更新公告";
      }
      this.loading = {
        dialog: true,
        message: msg,
        show_loading: true,
      };
    },
    createBulletin() {
      this.editItem = {
        id: null,
        title: "",
        content: "",
        link: "",
        type: 0,
        start_time: null,
        end_time: null,
        status: 0,
        start_time_menu: false,
        end_time_menu: false,
      };
      this.dialog = true;
    },
    onCreateBulletin() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/`;
      this.doHttpPost(
        url,
        this.editItem,
        () => {
          this.onSuccess("新建公告成功");
        },
        () => {
          this.onOperationFail("新建公告失敗");
        }
      );
    },
    onUpdateBulletin() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/${this.editItem.id}`;
      this.doHttpPut(
        url,
        this.editItem,
        () => {
          this.onSuccess("更新公告成功");
        },
        () => {
          this.onOperationFail("更新公告失敗");
        }
      );
    },
    onSuccess(msg) {
      this.getBulletins();
      this.editItem = {
        id: null,
        title: "",
        content: "",
        link: "",
        type: 0,
        start_time: null,
        end_time: null,
        status: 0,
        start_time_menu: false,
        end_time_menu: false,
      };
      this.dialog = false;
      setTimeout(() => {
        this.loading = {
          dialog: true,
          message: msg,
          show_loading: false,
          show_confirm: true,
        };
      }, 500);
    },

    editBulletin(item) {
      console.log("edit bulletin", item);
      this.editItem = item;
      this.dialog = true;
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
  },
};
</script>
